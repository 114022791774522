@import 'extra-styles/leaflet-slider';
@import 'extra-styles/product-selector';
@import 'extra-styles/date-selector';
@import 'extra-styles/area-select';
@import 'extra-styles/preview-map';
@import 'extra-styles/timeline-component';
@import 'extra-styles/leaflet-mouse-position';
@import 'extra-styles/dialog';
@import 'extra-styles/snackbar';

*[hidden] {
    display: none !important;
}
.margin-auto {
    margin: 0 auto;
}
button.mat-button-mid {
    transform: scale(0.8);
    margin-top: -1px;
    font-weight: normal;
}
