.leaflet-container .leaflet-control-mouseposition {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 5px #bbb;
    padding: 0 5px;
    margin: 0;
    color: #333;
    font:
        11px/1.5 'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
    float: left;
    position: absolute;
    left: -70px;
    bottom: -10px;
}

.default-coordinates {
    left: -135px !important;
}

.modified-coordinates {
    left: -180px !important;
}

.modified-coordinates-google-layers {
    left: -140px !important;
}

.leaflet-container .leaflet-control-mouseposition:hover {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
