@import '../../scss/variables';

.generic-dialog-wrapper {
    .mat-mdc-dialog-container {
        padding: 0 !important;
    }

    i {
        margin-right: 5px;
    }
}

dialog-title div {
    padding: 15px;
    background-color: $vds-dark;

    .title {
        color: white;
        font-size: 1.2em;
        font-weight: 500;
    }
}
