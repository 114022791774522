@import '../../scss/variables';

.custom-snackbar-success {
    --mdc-snackbar-container-color: #0f9960ff !important ;
    font-weight: $snackbar-font-weight;

    color: white;
}

.custom-snackbar-error {
    --mdc-snackbar-container-color: #db3737ff !important;
    font-weight: $snackbar-font-weight;

    color: white;
}

.custom-snackbar-warning {
    --mdc-snackbar-container-color: #ffff00ff !important;
    --mdc-snackbar-supporting-text-color: #000000 !important;
    font-weight: $snackbar-font-weight;
}

.custom-snackbar-release {
    --mdc-snackbar-container-color: #4aa4caff !important;
    font-weight: $snackbar-font-weight;

    $header-color: white;
    height: 59px;
}

.custom-snackbar-info {
    --mdc-snackbar-container-color: #4aa4caff !important;
    font-weight: $snackbar-font-weight;

    color: white;
}
