@import '../../scss/variables';

$timeline-general-border: #d8d3dd;
$timeline-base: #f1f1f1;
$timeline-lozenge: $vds-dark;
$timeline-text: #b1a7bc;
$timeline-stroke: #555555;

svg.timeline {
    overflow: hidden;
    shape-rendering: crispEdges;

    .main {
        text {
            text-anchor: start;
            fill: $timeline-text;

            :hover,
            :focus {
                fill: #32253f;
            }
        }
    }

    .nowLine {
        stroke: lightblue;
        stroke-width: 1.5px;
        stroke-dasharray: 2px, 2px;
    }

    .axis {
        line,
        path {
            stroke: $timeline-general-border;
        }

        text {
            font-size: 12px;
            color: $timeline-text;
        }
    }

    .y-axis {
        text.y-axis-label {
            fill: $timeline-text;
        }
    }

    .selectedDateLine {
        // rx: 5;
        // ry: 5;
        fill: $timeline-lozenge;
    }

    .detailLine {
        stroke-width: 1;
        stroke: $timeline-stroke;
    }

    .timeRange {
        fill: $timeline-base;
        stroke: none;
    }

    .timeline,
    .axis,
    .tick {
        cursor: pointer;
    }
}
