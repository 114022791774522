@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    height: 100%;
}

* {
    font-family: 'Roboto', serif;
    --mdc-fab-container-color: white !important;
    --mdc-icon-button-icon-size: unset !important;
}
.mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-label-text-color: white !important;
}
app-content {
    padding: 0;
}

simple-snack-bar {
    color: white;
}

/**
 * AppAccess directive extra style.
 */
.app-hide {
    display: none !important;
}

.info {
    padding: 6px 8px;
    font:
        14px/16px Arial,
        Helvetica,
        sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.info h4 {
    margin: 0 0 5px;
    color: #777;
}

.legend {
    line-height: 18px;
    color: #555;
}

.legend i {
    border-radius: 30%;
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
}

._epHidden {
    display: none !important;
}

@media print {
    .leaflet-control-container {
        display: none !important;
    }

    .app-header {
        display: none !important;
    }

    .info legend {
        display: none !important;
    }

    button.mat-mdc-mini-fab {
        display: none !important;
    }

    @page {
        size: A4 landscape;
        margin: 20mm 5mm 10mm 5mm;
    }
}

.txt-info {
    color: grey;
    font-size: 0.75em;
}

.w-100 {
    width: 100%;
}

.err-text {
    color: #f34339;
}

.mat-progress-bar-buffer {
    background-color: red;
}

.mat-progress-bar-fill::after {
    background-color: #28a745;
}
